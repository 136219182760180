<template>
	<form @submit.prevent="submitHandler">
		<recess-modal v-if="getFirstOrderLine && getFirstOrderLine.invoiceOnBehalfOf" :show-modal="isModalVisible" @close="$emit('close')">
			<template slot="modal-title">
				<h3>{{ PROVIDER.Order.Shared.Popups.EditInvoiceOnBehalf.Title }}</h3>
			</template>

			<template slot="modal-body">
				<div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
					<label class="w-30">{{ PROVIDER.Order.Shared.Popups.EditInvoiceOnBehalf.LegalCompany.Label }}</label>
					<recess-multi-select
						:selected-options="selectedLegalCompanyId"
						:options="legalCompanyOptions"
						:search-input="true"
						:single-select="true"
						:select-options-text="PROVIDER.Order.Shared.Popups.EditInvoiceOnBehalf.LegalCompany.SelectText"
						class="w-70 qa-update-legal-company"
						@input="(newValue) => legalCompanySelectHandler(newValue)"
						@close="onTouch"
					/>
					<p class="mt-3 font-italic">
						{{ PROVIDER.Order.Shared.Popups.EditInvoiceOnBehalf.FirstParagraph.PartOne }}
						<recess-button
							:title="PROVIDER.Order.Shared.Popups.EditInvoiceOnBehalf.FirstParagraph.LegalCompanyButton"
							variant="variant4"
							class="mb-4"
							url="/leveranciersgegevens/handelsnamen"
						/>
						{{ PROVIDER.Order.Shared.Popups.EditInvoiceOnBehalf.FirstParagraph.PartTwo }}
					</p>
				</div>
				<template v-if="selectedLegalCompanyId && legalCompanyDetails">
					<legal-company-detail :legal-company-details="legalCompanyDetails" />
				</template>
			</template>

			<template slot="modal-footer">
				<div class="row">
					<div class="col-12 d-flex justify-content-end">
						<recess-button variant="tertiary" :title="BUTTON_TEXT.cancel" class="mr-4 qa-edit-invoice-on-behalf-of-cancel-button" @click.native.prevent="close()" />
						<recess-button variant="secondary" :title="BUTTON_TEXT.submit" class="qa-edit-invoice-on-behalf-of-submit-button" />
					</div>
				</div>
			</template>
		</recess-modal>
	</form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { getItems, patchItem, getItemById } from '@/../../shared/api/SharedClient'

import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { legalCompanySuccessAction } from '@/../../shared/constants/validationMessageHelper'
import { defaultErrorMessage } from '../../../../shared/constants/validationMessageHelper'

const LegalCompanyDetail = () => import('@/components/molecules/shared/LegalCompanyDetail')

export default {
	name: 'EditInvoiceOnBehalfOfPopupComponent',
	components: {
		LegalCompanyDetail
	},
	props: {
		isModalVisible: {
			type: Boolean,
			default: false
		}
	},
	validations: {
		getFirstOrderLine: {
			invoiceOnBehalfOf: {
				name: { required },
				vatNumber: { required }
			}
		}
	},
	data() {
		return {
			PROVIDER,
			BUTTON_TEXT,
			legalCompanyList: [],
			selectedLegalCompanyId: null,
			legalCompanyDetails: null,
			timeout: null,
			providerId: localStorage.getItem('providerId')
		}
	},
	computed: {
		...mapState('orderModule', ['order']),
		...mapState('providerModule', ['provider']),
		...mapGetters('orderModule', ['getFirstOrderLine']),
		legalCompanyOptions() {
			if (this.legalCompanyList) {
				return this.legalCompanyList.map((item) => {
					return {
						value: item.id,
						displayText: item.name
					}
				})
			}
			return null
		},
		legalCompanyFilter() {
			return this.providerId ? `providerId eq ${this.providerId} and deleted eq false` : null
		}
	},
	watch: {
		provider: {
			handler() {
				if (this.provider && this.provider.id !== this.providerId) {
					this.providerId = this.provider.id
				}
			},
			deep: true
		},
		isModalVisible: function watchPropsChange(newValue) {
			// reset data status when modal is closed by watching the value
			// change of isModalvisible from its parent 'OrderDetailsPage'
			if (newValue === false) {
				this.resetData()
			}
		}
	},
	mounted() {
		// Code that will run only after the entire view has been rendered
		// We need this otherwise the token will not be sent in the request header through axios
		this.$nextTick(() => {
			if (this.provider) {
				this.providerId = this.provider.id
				this.getLegalCompanyList()
			}
		})
	},
	beforeDestroy() {
		clearTimeout(this.timeout)
	},
	methods: {
		...mapActions('orderModule', ['getOrder']),
		...mapMutations('orderModule', ['setInvoiceOnBehalfOfName', 'setInvoiceOnBehalfOfVatNumber', 'setInvoiceOnBehalfOfMerchantId']),
		close() {
			this.$emit('close')
		},
		onTouch() {
			this.isTouched = true
		},
		resetData() {
			this.$v.$reset()
			this.selectedLegalCompanyId = null
			this.legalCompanyDetails = null
			this.timeout = null
		},
		updateInvoiceOnBehalfOfName(invoiceOnBehalfOfName) {
			this.setInvoiceOnBehalfOfName(invoiceOnBehalfOfName)
		},
		updateInvoiceOnBehalfOfVatNumber(invoiceOnBehalfOfVatNumber) {
			this.setInvoiceOnBehalfOfVatNumber(invoiceOnBehalfOfVatNumber)
		},
		updateInvoiceOnBehalfOfMerchantId(invoiceOnBehalfOfMerchantId) {
			this.setInvoiceOnBehalfOfMerchantId(invoiceOnBehalfOfMerchantId)
		},
		legalCompanySelectHandler(newValue) {
			this.selectedLegalCompanyId = newValue
			this.getLegalCompanyDetails()
		},
		async getLegalCompanyList() {
			try {
				const response = await getItems(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.legalCompanies, 1, 99999, 'name asc', this.legalCompanyFilter, false)
				if (!response) return
				this.legalCompanyList = response.items
			} catch (error) {
				console.error('Something went wrong while retrieving legal company list', error)
			}
		},
		async getLegalCompanyDetails() {
			if (!this.selectedLegalCompanyId) return
			try {
				const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.legalCompanies, this.selectedLegalCompanyId, null, false)
				if (!response) return
				this.legalCompanyDetails = response 
			} catch (error) {
				console.error('Something went wrong while retrieving legal companies', error)
			}
		},
		submitHandler() {
			// no need to patch legal company,
			// only patch 'invoice on behalf of' information on each order line
			this.$v.$touch()
			if (!this.$v.$invalid) {
				this.updateInvoiceOnBehalfOfName(this.legalCompanyDetails.name)
				this.updateInvoiceOnBehalfOfVatNumber(this.legalCompanyDetails.vatNumber)
				this.updateInvoiceOnBehalfOfMerchantId(this.legalCompanyDetails.merchantId)
				
				this.patchOrderLinesForEachSequenceNumber(
					this.getFirstOrderLine.invoiceOnBehalfOf.name,
					this.getFirstOrderLine.invoiceOnBehalfOf.vatNumber,
					this.getFirstOrderLine.invoiceOnBehalfOf.merchantId
				) 
			}
		},
		setPatchPromiseList(patchPromiseList, invoiceOnBehalfOfName, invoiceOnBehalfOfVatNumber, invoiceOnBehalfOfMerchantId) {
			this.order.orderLines.forEach((orderLine) => {
				const patchObjList = [
					{
						value: invoiceOnBehalfOfName,
						path: 'invoiceOnBehalfOfName',
						op: 'replace'
					},
					{
						value: invoiceOnBehalfOfVatNumber,
						path: 'invoiceOnBehalfOfVatNumber',
						op: 'replace'
					},
					{
						value: invoiceOnBehalfOfMerchantId,
						path: 'invoiceOnBehalfOfMerchantId',
						op: 'replace'
					}
				]

				patchPromiseList.push(patchItem(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.orders, `${this.order.id}/lines/${orderLine.sequenceNumber}`, patchObjList, false))
			})
		},
		patchOrderLinesForEachSequenceNumber(invoiceOnBehalfOfName, invoiceOnBehalfOfVatNumber, invoiceOnBehalfOfMerchantId) {
			const patchPromiseList = []

			this.setPatchPromiseList(patchPromiseList, invoiceOnBehalfOfName, invoiceOnBehalfOfVatNumber, invoiceOnBehalfOfMerchantId)

			Promise.all(patchPromiseList)
				.then(() => {
					const toastNotification = {
						type: 'success',
						message: legalCompanySuccessAction('bewerkt')
					}

					this.$store.dispatch('toastNotificationModule/add', toastNotification, {
						root: true
					})

					this.close()
				})
				.catch((error) => {
					this.getNotificationError()
					console.error('Something went wrong while patching order lines', error)
					this.close()
					this.getOrder(this.order.id) // reset to the original values shown on order details page
				})
		},
		getNotificationError() {
			const toastNotification = {
				type: 'error',
				message: defaultErrorMessage
			}

			this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				root: true
			})
		}
	}
}
</script>